import clsx from 'clsx'
import { type ReactNode } from 'react'
import { Anchor } from '~/components/ui/link'

type HeaderProps = {
    children?: ReactNode
    boxed?: boolean
}

export default function Navbar({ boxed, children }: HeaderProps) {
    const navStyle = clsx(
        'h-12 flex-none border-solid border-1 border-b border-border-subtle-00',
        boxed && 'px-4'
    )

    const innerContentStyle = clsx(
        'relative flex box-border justify-between items-center h-full',
        boxed && 'max-w-[1600px] w-full mx-auto'
    )

    const logoLinkStyle = clsx(
        'text-base flex h-12 items-center justify-center px-4 font-semibold text-text-primary !no-underline ring-inset ring-border-interactive',
        'hover:bg-background-hover hover:text-text-primary',
        'focus:ring-3 focus:ring-border-interactive focus-visible:ring-3 focus-visible:ring-border-interactive',
        'active:bg-background-active'
    )

    return (
        <nav className={navStyle}>
            <div className={innerContentStyle}>
                <Anchor href='/' $size='primary' className={logoLinkStyle}>
                    Infinity
                </Anchor>
                {children}
            </div>
        </nav>
    )
}
