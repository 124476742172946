import { Link } from '@remix-run/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

export type AnchorProps = {
	href?: string
	$size: 'primary' | 'secondary'
	$style?: 'primary' | 'secondary'
	className?: string
	children: ReactNode
	noNavigate?: boolean
	noUnderline?: boolean
	noHoverColor?: boolean
	onClick?: (event: MouseEvent) => void
	prefetch?: 'intent' | 'none'
}

export function Anchor({
	href,
	$size,
	$style = 'primary',
	onClick,
	children,
	className,
	noNavigate,
	noHoverColor = false,
	noUnderline,
	prefetch = 'intent'
}: AnchorProps) {
	const Component = href ? Link : 'a'
	return (
		<Component
			className={clsx(
				'cursor-pointer no-underline',
				'ring-border-interactive !outline-none focus:ring-3 focus-visible:ring-3',
				!noUnderline && 'hover:underline',
				!noUnderline && 'active:underline',
				$size === 'primary' && 'text-base',
				$size === 'secondary' && 'text-small',
				$style === 'primary' &&
				!noHoverColor && 'text-link-primary hover:text-link-primary-hover active:text-link-primary-active',
				$style === 'secondary' &&
				'text-text-secondary hover:text-link-secondary-hover active:text-link-secondary-active',
				className
			)}
			{...(prefetch ? { prefetch } : {})}
			{...(href ? { to: href } : {})}
			{...(noNavigate && onClick ? { onClick } : {})}
		>
			{children}
		</Component>
	)
}
